import React from 'react'
import './css/about.css'
import SectionTitle from './SectionTitle'
import Button from "./Button";
import { Link } from 'react-scroll';
import aboutImg from "../assets/img/about.webp";
import OfferItem from './OfferItem';
import { FaCode, FaComputer,FaGears  } from "react-icons/fa6";


const About = () => {
  return (
    <div className='about section' id="about">
      <div className='container'>
        <SectionTitle title="O mnie"/>
        <div className='about-intro'>
            <div className='about-bg'>
              <div className='about-img'>
              </div>
              <Link 
                activeClass="active" 
                to="contact" 
                spy={true} 
                smooth={true} 
                duration={500}>
                 <Button className="btn btn2 btn-mobile" txt="Kontakt"/>
              </Link>  
            </div>
            <div>
              <p className='about-title'><span>Witam </span>na mojej stronie!</p>
              <p> Mam na imię Tomek. Od 10 lat zajmuje się tworzeniem i administracją stron internetowych.<br/> <br/>
              Początkowo moje główne zadania wiązały się z obsługą projektów. Byłem pośrednikiem pomiędzy Klientami, a grafikami i programistami realizującymi strony. Odpowiadałem także za administrację i uzupełnianie stron Klientów o nowe materiały.
              <br/><br/> 
              Z biegiem czasu coraz bardziej poznawałem tajniki tworzenia stron internetowych, aż w końcu sam zacząłem realizować projekty. W swojej pracy wykorzystuję najczęściej CMS Wordpress, nie są mi także obce zagadnienia związane z html, css, php, js, ReactJs.
              <br/>  <br/>
              Praca ze stronami internetowymi stała się moją pasją, którą staram się rozwijać i stale uzupełniać o nową wiedzę i umiejętności.
              </p>
              <Link 
                activeClass="active" 
                to="contact" 
                spy={true} 
                smooth={true} 
                duration={500}>
                 <Button className="btn btn2" txt="Kontakt"/>
              </Link>
            </div>
        </div>
        <div>
            <div className='about-offer'>
              <p className='about-title'><span>W czym </span>mogę Ci pomóc?</p>
              <p className='about-text'>Przez lata pracy w agencji interaktywnej oraz w obszarze stron internetowych 
                zdobyłem niezbędne doświadczenie. Zobacz w jakim zakresie mogę Ci pomóc.</p>
             <div className='offerItems'>
                <OfferItem icon={<FaCode />} title='Strony internetowe' text='Realizuję strony internetowe w oparciu o system CMS Wordpress.
                 Mogę także stworzyć statyczną stronę internetową przy użyciu ReactJS, html, css.'/>
                 <OfferItem icon={<FaComputer />} title='Administracja stronami' text='Uzupełnię stronę o materiały otrzymane od Ciebie, dokonam drobnych poprawek lub rozbudowy strony o nowe funkcje.'/>
                 <OfferItem icon={<FaGears  />} title='Inne' text='Potrzebujesz wsparcia przy aktualizacji silnika Wordpress, wtyczek lub konfiguracji domen i certyfikatów ssl? Napisz do mnie, postaram się pomóc.'/>
             </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default About