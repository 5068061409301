import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import About from "./components/About";
import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";
import ScrollTop from "./components/ScrollTop";

function App() {
  return (
    <div>
       <Navbar/>
       <Hero/>
       <About/>
       <Portfolio/>
       <Contact/>
       <ScrollTop/>
    </div>
  );
}

export default App;
