import React from 'react'
import './css/realization.css'
import Button from "./Button";
import { Link } from 'react-scroll';
import { FaEye } from "react-icons/fa6";



const Realization = (props) => {

  return (
    <div className='realization-item'>
     
      
        <div  className='realization'>
          <div className='realization-title'>{props.title}</div>
          <div className='realization-img' style={{backgroundImage: `url(${props.img})`}}>
          </div>
          <div className='realization-info'>{props.info}</div>
        </div>
        <a href={props.url} target="_blank" className='link'>
          <div className='realization-bg'>
            <div className='realization-title2'>
              {props.title}
              <FaEye className='realization-icon' />
              <p style={{fontSize:"12px"}}>Zobacz online</p>
            </div>
          </div>
        </a>
    </div>
  )
}

export default Realization