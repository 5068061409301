import React from 'react'
import './css/sectionTitle.css'

const SectionTitle = (props) => {
  return (
    <div className='section-title'>
        <h2>{props.title}</h2>
    </div>
  )
}

export default SectionTitle