import React from 'react'
import './css/button.css'
const Button = (props) => {
  return (
    <div className={ props.className } onClick={props.onClick}>
        { props.txt }
    </div>
  )
}

export default Button