import React, {useState, useEffect} from 'react'
import './css/scrollTop.css'
import {animateScroll as scroll } from 'react-scroll';
import {FaAngleUp } from "react-icons/fa6";


const ScrollTop = () => {
    const [isScroll, setIsScroll] = useState(false);

    useEffect(()=>{
        window.addEventListener("scroll", () =>{
          if(window.scrollY > 600){
            setIsScroll(true)
          } else {
            setIsScroll(false)
          }
        })
      }, [])
    
      const scrollToTop = () => {
        scroll.scrollToTop();
      };
    

  return (
    <div className={isScroll ? 'scroll-active scroll-top ' : 'scroll-top'} onClick={scrollToTop}>
           <FaAngleUp className='scroll-icon'/>
    </div>
  )
}

export default ScrollTop