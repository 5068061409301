import React, { useRef, useState} from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup"
import { FaShare } from "react-icons/fa6";  
import emailjs from '@emailjs/browser';
import * as yup from "yup"
import './css/form.css'

const schema = yup.object().shape({
    name: yup.string().max(60,'Imię nie może być dłuższe niż 60 znaków').required("Podaj swoje imię"),
    email: yup.string().email('Podaj poprawny adres mail').required("Adres email jest obowiązkowy"),
    phone: yup.number().typeError('Podaj poprawny numer telefonu').positive().integer(),
    message: yup.string(),
    acceptTerms: yup.bool().oneOf([true], 'Musisz wyrazić zgodę aby przesłać formularz')
  });

const Form = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const form = useRef();

    const[isSend, setIsSend] = useState(false);

    const onSubmit = () => {
 
     emailjs.sendForm(
         'service_atia58v', 
         'template_8iwjpd9', 
        form.current, 
         'd_4TbJLJs0p_ahVrk'
         )
       .then((result) => {
           console.log(result.text);
       }, (error) => {
           console.log(error.text);
       });
          setIsSend(true);
          reset();
         };


  return (
    <>
    {isSend &&
     <div className='success-message'>
         <FaShare style={{color:"var(--primary)", fontSize:"50px"}}/>
         <p>Dziękuję za przesłanie formularza. <br/>Wkrótce odpowiem na Twoją wiadomość. </p> 
    </div> 
    }
   
    <div className='form'>
          <form ref={form} onSubmit={handleSubmit(onSubmit)}>
            <div className='form-group'>
                <label htmlFor='name'>Imię</label>
                <input id="name" type="text" {...register("name")} />
                {errors.name && <span className="form-error">{errors.name?.message}</span>}
            </div>
            <div className='form-group'>
                <label htmlFor='email'>Email</label>
                <input id="email" type="text" {...register("email")} />
                {errors.email && <span className="form-error">{errors.email?.message}</span>}
            </div>
            <div className='form-group'>
                <label htmlFor='phone'>Telefon</label>
                <input id ="phone" type="phone" {...register("phone")} />
                {errors.phone && <span className="form-error">{errors.phone?.message}</span>}
            </div>
            <div className='form-group'>
                <label htmlFor='message'>Wiadomość</label>
                <textarea rows='6' id="message" {...register("message", {})} />
            </div>
            <div className='acceptTerms'>
                <input type="checkbox" value="true" {...register('acceptTerms')} id="acceptTerms" />
                <label htmlFor="acceptTerms">Zgadzam się na przetwarzanie moich danych osobowych. </label>
            </div>
            {errors.acceptTerms && <span className="form-error">{errors.acceptTerms?.message}</span>}
            <button type="submit" className='btn btn2 form-btn'>Wyślij</button>
        
        </form>
    </div>
    </>
  )
}

export default Form