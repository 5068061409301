import React from 'react'
import './css/hero.css'
import Button from "./Button";
import { Link } from 'react-scroll';
import { FaAnglesDown } from "react-icons/fa6";

const Hero = () => {
  return (
    <div className='hero'>
        <div className='container'>
          <div className='hero-info'>
            <h1 className='hero-title'>
              Portfolio <span style={{color: "#fff"}}>Strony internetowe</span>
            </h1>
            <p className='hero-txt'>
            
              Zapraszam do zapoznania się z moimi realizacjami. Jeśli zainteresowały Cię zaprezentowane prace, zapraszam do kontaktu. 
            </p>
            <div className='buttons-group'>
              <Link 
                activeClass="active" 
                to="portfolio" 
                spy={true} 
                smooth={true} 
                duration={500}>
                <Button className="btn" txt="Portfolio"/>
              </Link>
              <Link 
                activeClass="active" 
                to="contact" 
                spy={true} 
                smooth={true} 
                duration={500}>
                 <Button className="btn btn2" txt="Kontakt"/>
              </Link>
            </div>
          </div>
        </div>
        <Link 
          activeClass="active" 
          to="about" 
          spy={true} 
          smooth={true} 
          duration={500}>
          <FaAnglesDown className='hero-down-arrow' />
        </Link> 
    </div>
  
  )
}

export default Hero