import React from 'react'
import './css/contact.css'
import SectionTitle from './SectionTitle'
import { FaEnvelope } from "react-icons/fa6";
import Form from './Form';
import contactForm from '../assets/img/contact-form.webp';


const Contact = () => {
  return (
    <div className='contact section' id="contact">
       <div className='container'>
         <SectionTitle title="Kontakt"/>
         <p>Zapraszam do kontaktu. Z pewnością odpowiem na wszystkie otrzymane wiadomości. 
          <br/>Zachęcam także do wyełnienia poniższego formularza. </p>
          <div className='contact-mail'>
            <FaEnvelope className='contact-mail-icon' /> 
            <a href='mailto:kontakt@tomd.pl'>kontakt@tomd.pl</a>
          </div>
          <div className='contact-form'>
                 <div className='contact-form-img'>
                    <img src={contactForm}/>
                </div>
              <Form/>
          </div>
        </div>
     </div>
  )
}

export default Contact