import React from 'react'
import './css/offerItem.css'


const OfferItem = (props) => {
  return (
    <div className='offer-item'>
            <div className='item-icon'>{props.icon}</div>
            <div className='item-title'>{props.title}</div>
            <div>{props.text}</div>
            <div className='item-icon-big'>{props.icon}</div>
    </div>
  )
}

export default OfferItem