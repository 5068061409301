import React, {useState, useEffect} from 'react'
import './css/navbar.css'
import { Link} from 'react-scroll';
import { FaBars, FaXmark } from "react-icons/fa6";

const Navbar = () => {

  const [ismobile, setIsmobile] = useState(false);
  
  const handleMobile = () => {
    setIsmobile(!ismobile)
  }

  return (
    <div className='navbar'>
        <div className='container'>
            <div className='nav'>
                <div className='logo'>tomd.pl</div>
                <ul className={ismobile ? 'menu mobile-active' : 'menu'}>
                 <li>Home</li>
                 <li>
                    <Link 
                        activeClass="active" 
                        to="about" 
                        spy={true} 
                        smooth={true} 
                        duration={500}>
                        O mnie
                    </Link>
                 </li>
                 <li>
                    <Link 
                          activeClass="active" 
                          to="portfolio" 
                          spy={true} 
                          smooth={true} 
                          duration={500}>
                          Portfolio
                    </Link>
                  </li>
                  <li>
                    <Link 
                          activeClass="active" 
                          to="contact" 
                          spy={true} 
                          smooth={true} 
                          duration={500}>
                          Kontakt
                    </Link>
                  </li>
                 </ul> 
                  <div className='mobile-nav'>
                    <div className='menu-icon'  onClick={handleMobile}>
                      {ismobile ? <FaXmark /> : <FaBars />}
                    </div>
                  </div>
            </div>
        </div>
    </div>
  )
}

export default Navbar